import styled from "styled-components";
import { PAGE_CONTENT_WIDTH } from "./PageConstants";

export const InterFont = `Inter, -apple-system, BlinkMacSystemFont, San Francisco, Roboto, Segoe UI, Helvetica Neue, sans-serif`;
export const AndersonFont = `'Anderson Grotesk', -apple-system, BlinkMacSystemFont, San Francisco, Roboto, Segoe UI, Helvetica Neue, sans-serif`;

export const PageWrapper = styled.div`
  min-height: 100%;
  display: flex;
  flex-direction: column;
  padding-bottom: 16px;
  width: 100vw;
`;

export const PageContent = styled.div`
  max-width: ${PAGE_CONTENT_WIDTH}px;
  width: 100%;
  margin: 0 auto;
  padding-left: 32px;
  padding-right: 32px;

  @media only screen and (max-width: ${PAGE_CONTENT_WIDTH}px) {
    padding-left: 16px;
    padding-right: 16px;
  }
`;

export const PageLink = styled.a`
  position: relative;
  font-weight: 500;
  color: ${({ theme }) => theme.link};
  transition: 0.2s all ease-in-out;
  text-decoration: none;

  ${({ theme }) =>
    theme.isDark
      ? `background-image: linear-gradient(
          ${theme.lightYellow} 0%,
          ${theme.lightYellow} 100%
        );`
      : `background-image: linear-gradient(
          transparent 80%,
          ${theme.accent} 12px
        );`}

  background-size: 0;
  background-repeat: no-repeat;

  &::after {
    position: absolute;
    content: "";
    background-color: ${({ theme }) =>
      theme.isDark ? theme.lightYellow : theme.accent};
    height: ${({ theme }) => (theme.isDark ? "120%" : "8px")};
    ${({ theme }) => (theme.isDark ? "top: -10%;" : "top: 100%;")}
    ${({ theme }) => (theme.isDark ? "" : "transform: translateY(-100%);")}
    white-space: nowrap;
  }

  &:hover {
    background-size: 100%;
    color: ${({ theme }) => (theme.isDark ? theme.dark1 : theme.link)};
  }
`;

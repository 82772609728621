import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import HomePage from "./pages/HomePage";
import NotFoundPage from "./pages/NotFound";

const Routing = () => (
  <Router>
    <Routes>
        <Route path="/" element={<HomePage/>} />
        <Route path="*" element={<NotFoundPage/>} />
    </Routes>
  </Router>
);

export default Routing;

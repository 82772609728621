import React from "react";
import Toggle from "react-toggle";

import { ThemeContext } from "../context/ThemeContext";

import "react-toggle/style.css";
import styled from "styled-components";

const ThemeToggle = () => (
  <ThemeContext.Consumer>
    {({ theme, toggleTheme }) => (
      <ToggleWrapper>
        <Toggle
          checked={theme === "dark"}
          onChange={toggleTheme}
          icons={false}
        />
      </ToggleWrapper>
    )}
  </ThemeContext.Consumer>
);

export default ThemeToggle;

const ToggleWrapper = styled.div`
  display: inline-block;

  .react-toggle--focus,
  .react-toggle:hover {
    .react-toggle-track {
      background-color: ${({ theme }) => theme.primary}1A;
      filter: brightness(90%);
    }
  }

  .react-toggle--checked.react-toggle--focus,
  .react-toggle--checked.react-toggle:hover {
    .react-toggle-track {
      background-color: ${({ theme }) => theme.primary};
      filter: brightness(90%);
    }
  }

  .react-toggle-thumb {
    box-shadow: none;
    border: 1px solid ${({ theme }) => theme.dark3};
    width: 20px;
    height: 20px;
    left: 2px;
    top: 2px;
  }

  .react-toggle-track {
    background-color: ${({ theme }) => theme.primary}1A;
    border: 1px solid ${({ theme }) => theme.dark3};
  }

  .react-toggle--checked {
    .react-toggle-thumb {
      left: 27px;
    }

    .react-toggle-track {
      background-color: ${({ theme }) => theme.primary};
      border: none;
    }
  }
`;

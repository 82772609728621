import { GitHub, Mail, Linkedin, FileText } from "react-feather";
import styled, { useTheme } from "styled-components";

import { PageLink, PageContent, PageWrapper } from "../constants/Mixins";
import Project from "../components/Project";
import ThemeToggle from "../components/ThemeToggle";

import Resume from '../assets/resume.pdf'
import Visualization from '../assets/f-cover-visualization.png'
import Graph from '../assets/graph.png'
import Chess from '../assets/brilliant.png'

const HomePage = () => {
  const theme = useTheme();

  return (
    <PageWrapper>
      <PageContent>
        <Header>
          <Name>Monir Nasserie</Name>
          <Subtitle>Cloud &amp; Software Engineer</Subtitle>
          <Icons>
            <IconLink
              href={"https://github.com/MN-Coding"}
              target="_blank"
              rel="noopener noreferrer"
            >
              <GitHub />
            </IconLink>
            <IconLink
              href={"mailto:monirnasserie@gmail.com"}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Mail />
            </IconLink>
            <IconLink
              href={"https://www.linkedin.com/in/monir-nasserie/"}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Linkedin />
            </IconLink>
            <IconLink
              href={Resume}
              target="_blank"
              rel="noopener noreferrer"
            >
              <FileText />
            </IconLink>
          </Icons>
          <ThemeToggle />
        </Header>
        <Section>
          <SectionTitle>About Me</SectionTitle>
          <Description>
            Hey, I'm Monir! 👋
          </Description>
          <Description>
            I'm a 4th year Computer Science Student (4B) at the{" "}
            <PageLink
              href={"https://uwaterloo.ca/"}
              target="_blank"
              rel="noopener noreferrer"
            >
              University of Waterloo
            </PageLink>
            , and currently a Software Developer Intern at{" "}
            <PageLink
              href={"https://www.tesla.com/"}
              target="_blank"
              rel="noopener noreferrer"
            >
                Tesla
            </PageLink>
            .
          </Description>
          <Description>
            Alongside my studies, I work as a Software Developer on the Science & Technology Branch at{" "}
            <PageLink
              href={"https://agriculture.canada.ca/en"}
              target="_blank"
              rel="noopener noreferrer"
            >
              Agriculture and Agri-Food Canada
            </PageLink>
            {" "}, where I've been leveraging{" "} 
            <PageLink
              href={"https://developers.google.com/earth-engine"}
              target="_blank"
              rel="noopener noreferrer"
            >
              Google Earth Engine
            </PageLink>
            {" "} in conjunction with interpolation techniques to produce{" "}
            <PageLink
              href={Visualization}
              target="_blank"
              rel="noopener noreferrer"
            >
              images
            </PageLink>
            {" "}of regional agriculture metrics and compile statistical{" "}
            <PageLink
              href={Graph}
              target="_blank"
              rel="noopener noreferrer"
            >
              summaries
            </PageLink>
            .
          </Description>
          <Description>
            Previously, I've interned at{" "}
            <PageLink
              href={"https://www.dealer-fx.com/"}
              target="_blank"
              rel="noopener noreferrer"
            >
              Dealer-FX
            </PageLink>
            {" "}as a Software Developer on the Platform Team,{" "}
            <PageLink
              href={"https://www.telus.com/en/health/health-professionals/clinics/ps-suite"}
              target="_blank"
              rel="noopener noreferrer"
            >
              TELUS Health
            </PageLink>
            {" "}as a Software Developer on PS Suite EMR, LifeWorks (now TELUS Health) as a Cloud Engineer &amp;{" "}
            <PageLink
              href={"https://altaml.com/"}
              target="_blank"
              rel="noopener noreferrer"
            >
              AltaML
            </PageLink>
            {" "}as a Machine Learning Developer.
          </Description>
          <Description marginBottom={0}>
            In my free time, I like to workout, swim, play Elden Ring, do puzzles and play{" "}
            <PageLink
              href={Chess}
              target="_blank"
              rel="noopener noreferrer"
            >
              chess
            </PageLink>
            .
          </Description>
        </Section>
        <Section>
          <SectionTitle>Projects</SectionTitle>
          <Project
            title="GymBro"
            description="A fitness web (MERN) application allowing users to track & visualize progress"
            codeUrl="https://github.com/MN-Coding/GymBro"
            tags={["Development", "Web"]}
            tagColors={[theme.green, theme.orange]}
            margin="0"
          />
          <Project
            title="OnlyTheFam"
            description="Family activity planner mobile application"
            codeUrl="https://github.com/MN-Coding/OnlyTheFam"
            demoUrl="https://youtu.be/YIAbUzhDsXE"
            tags={["Development", "Mobile"]}
            tagColors={[theme.green, theme.red]}
          />
          <Project
            title="ML Fraud Detection Models Comparison"
            description="Comparing and contrasting performance of ML models/algorithms for detecting fraudulent credit card transactions"
            codeUrl="https://github.com/MN-Coding/Credit-Card-Fraud-Detection/tree/main"
            tags={["Machine Learning"]}
            tagColors={[theme.blue]}
          />
        
        </Section>
      </PageContent>
    </PageWrapper>
  );
};

export default HomePage;

const Header = styled.div`
  margin: 120px auto 100px auto;
`;

const Name = styled.div`
  font-weight: 700;
  font-size: 48px;
  margin-bottom: 8px;
  color: ${({ theme }) => theme.primary};
`;

const Subtitle = styled.div`
  font-weight: 400;
  font-size: 20px;
  margin-bottom: 40px;
  color: ${({ theme }) => theme.subtitle};
`;

const IconLink = styled(PageLink)`
  &:hover {
    background-size: 0;
    svg {
      fill: ${({ theme }) => theme.accent};
      transition: 0.2s all ease-in-out;
    }
  }
`;

const Icons = styled.div`
  display: inline-block;
  margin-right: 24px;

  svg {
    width: 22px;
    margin-right: 16px;
    stroke: ${({ theme }) => theme.subtitle};
  }
`;

const Description = styled.div<{ marginBottom?: number }>`
  line-height: 1.5;
  margin-bottom: ${({ marginBottom = 24 }) => marginBottom}px;
`;

const Section = styled.div`
  margin-bottom: 100px;
`;

const SectionTitle = styled.div`
  display: inline-block;
  position: relative;
  font-weight: 700;
  font-size: 20px;
  margin-bottom: 32px;

  &::after {
    content: "";
    background-color: ${({ theme }) => theme.blue};
    height: 8px;
    position: absolute;
    top: 60%;
    left: 0;
    width: 100%;
    z-index: -1;
    opacity: 0.5;
    transition: 0.2s all ease-in-out;
  }
`;

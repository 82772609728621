export const CommonTheme = {
    purple: "#b388ff",
    blue: "#80d8ff",
    darkBlue: "#304ffe",
    green: "#00c853",
    lightYellow: "#E6E6FA",
    yellow: "#ffff8d",
    red: "#ff899b",
    orange: "#ffc400",
  
    dark1: "#172b4d",
    dark2: "#505f79",
    dark3: "#97a0af",
  
    light1: "#f4f5f7",
    light2: "#ebecf0",
    light3: "#dfe1e5",
    light4: "#c6c9c9",
  
    black: "#000000",
    white: "#ffffff",
    transparent: "rgba(0,0,0,0)",
  
    breakpoints: {
      zero: 0,
      mobile: 600,
    },
  };
  
  const Theme = {
    light: {
      ...CommonTheme,
      text: CommonTheme.black,
      background: CommonTheme.white,
      primary: "#5e9aff",
      link: "#5e9aff",
      accent: "#B0E0E6",
      subtitle: CommonTheme.dark2,
      isDark: false,
    },
    dark: {
      ...CommonTheme,
      text: CommonTheme.white,
      background: "#020202",
      primary: "#9966CC",
      link: "#9966CC",
      accent: "#9966CC",
      subtitle: CommonTheme.light3,
      isDark: true,
    },
  };
  
  export default Theme;
  